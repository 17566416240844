var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"col-8 text-center"},[(_vm.isPageLoading)?_c('i',{staticClass:"fa fa-cog fa-spin fa-4x"}):_vm._e(),(_vm.isPageLoading)?_c('div',{attrs:{"id":"overlay"}}):_vm._e()]),_c('table',{staticClass:"table"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("ID")]),_c('td',[_vm._v(_vm._s(_vm.member.Id))])]),_c('tr',[_c('td',[_vm._v("Код")]),_c('td',[_vm._v(_vm._s(_vm.member.Code))])]),_c('tr',[_c('td',[_vm._v("Позывной")]),_c('td',[_vm._v(_vm._s(_vm.member.Callsign))])]),_c('tr',[_c('td',[_vm._v("Группировка")]),_c('td',[_vm._v(_vm._s(_vm.member.Fraction.Title))])]),_c('tr',[_c('td',[_vm._v("Костюм")]),_c('td',[_vm._v(_vm._s(_vm.member.Suit.Title))])]),_c('tr',[_c('td',[_vm._v("Легенда")]),_c('td',[_c('span',{staticClass:"btn-link",staticStyle:{"cursor":"pointer"},attrs:{"role":"button"},on:{"click":function($event){return _vm.showLegend()}}},[_vm._v("\n            "+_vm._s(_vm.member.Legend.length > 12
                ? _vm.member.Legend.substring(0, 12) + "..."
                : _vm.member.Legend)+"\n          ")])])]),_c('tr',[_c('td',[_vm._v("Ранг")]),_c('td',[_vm._v(_vm._s(_vm.getRank(_vm.member.Rank)))])]),_c('tr',[_c('td',[_vm._v("Опыт")]),_c('td',[_vm._v(_vm._s(_vm.member.Experience))])]),_c('tr',[_c('td',[_vm._v("Деньги")]),_c('td',[_vm._v(_vm._s(_vm.member.Money)+" руб.")])]),_c('tr',[_c('td',[_vm._v("Создан")]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(_vm.member.CreatedAt)))])]),_c('tr',[_c('td',[_vm._v("Фото на паспорт")]),_c('td',[_c('img',{staticClass:"image",attrs:{"src":_vm.baseURL + _vm.member.PassportPhoto.Path,"width":"100","height":"133"}})])]),_c('tr',[_c('td',[_vm._v("Фото на допуск")]),_c('td',[_c('gallery',{attrs:{"images":_vm.images,"index":_vm.index},on:{"close":function($event){_vm.index = null}}}),_vm._l((_vm.images),function(image,imageIndex){return _c('div',{key:imageIndex,staticClass:"image",style:({
              backgroundImage: ("url( " + image + ")"),
              width: '100px',
              height: '133px'
            }),on:{"click":function($event){_vm.index = imageIndex}}})})],2)]),_c('tr',[_c('td',[_vm._v("Паспорт")]),_c('td',[(
              _vm.member.PassportImages.FrontImg.length > 0 &&
                _vm.member.PassportImages.BackImg.length > 0 &&
                _vm.member.PassportImages.QRCodeImg.length > 0
            )?_c('div',[_c('p',[_c('a',{attrs:{"href":_vm.baseURL + _vm.member.PassportImages.FrontImg,"target":"_blank"}},[_vm._v("лицевая сторона")])]),_c('p',[_c('a',{attrs:{"href":_vm.baseURL + _vm.member.PassportImages.BackImg,"target":"_blank"}},[_vm._v("тыльная сторона")])]),_c('p',[_c('a',{attrs:{"href":_vm.baseURL + _vm.member.PassportImages.QRCodeImg,"target":"_blank"}},[_vm._v("QR-код")])])]):_vm._e(),_c('div',[(_vm.member.PassportImages.FrontImg.length === 0)?_c('b-button',{attrs:{"size":"sm","variant":"success"},on:{"click":_vm.passportGenerate}},[_vm._v("\n              Сгенерировать паспорт\n            ")]):_vm._e(),(_vm.member.PassportImages.FrontImg.length > 0)?_c('b-button',{attrs:{"size":"sm"},on:{"click":_vm.passportGenerate}},[_vm._v("\n              Перегенерировать паспорт\n            ")]):_vm._e()],1)])])])]),_c('b-modal',{ref:"legendModal",staticClass:"modal-info",attrs:{"size":"md","scrollable":"","ok-only":"","title":'Легенда сталкера ' + _vm.member.Callsign}},[_c('div',{staticClass:"d-block text-center"},[_c('p',[_vm._v(_vm._s(_vm.member.Legend))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }