<template>
  <div>
    <table class="table">
      <tbody>
        <tr>
          <td>ID</td>
          <td>{{ user.Id }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{ user.Email }}</td>
        </tr>
        <tr>
          <td>ФИО</td>
          <td>
            {{ user.LastName }}&nbsp;{{ user.FirstName }}&nbsp;{{
              user.FatherName
            }}
          </td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>{{ user.Phone }}</td>
        </tr>
        <tr>
          <td>Дата рождения</td>
          <td>{{ user.BornAt | formatDate }}</td>
        </tr>
        <tr>
          <td>Город</td>
          <td>{{ user.City.Name }}</td>
        </tr>
        <tr>
          <td>Команда</td>
          <td>{{ user.Command.Title }}</td>
        </tr>
        <tr>
          <td>Противопоказания</td>
          <td>
            {{
              user.Contraindications.length > 0 ? user.Contraindications : "Нет"
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "UserData",
  props: {
    user: Object
  },
  data() {
    return {};
  },
  computed: {},
  filters: {},
  methods: {}
};
</script>

<style scoped></style>
