<template>
  <div>
    <div class="col-8 text-center">
      <i v-if="isPageLoading" class="fa fa-cog fa-spin fa-4x"></i>
      <div v-if="isPageLoading" id="overlay"></div>
    </div>

    <table class="table">
      <tbody>
        <tr>
          <td>ID</td>
          <td>{{ member.Id }}</td>
        </tr>
        <tr>
          <td>Код</td>
          <td>{{ member.Code }}</td>
        </tr>
        <tr>
          <td>Позывной</td>
          <td>{{ member.Callsign }}</td>
        </tr>
        <tr>
          <td>Группировка</td>
          <td>{{ member.Fraction.Title }}</td>
        </tr>
        <tr>
          <td>Костюм</td>
          <td>{{ member.Suit.Title }}</td>
        </tr>
        <tr>
          <td>Легенда</td>
          <td>
            <span
              class="btn-link"
              style="cursor: pointer"
              @click="showLegend()"
              role="button"
            >
              {{
                member.Legend.length > 12
                  ? member.Legend.substring(0, 12) + "..."
                  : member.Legend
              }}
            </span>
          </td>
        </tr>
        <tr>
          <td>Ранг</td>
          <td>{{ getRank(member.Rank) }}</td>
        </tr>
        <tr>
          <td>Опыт</td>
          <td>{{ member.Experience }}</td>
        </tr>
        <tr>
          <td>Деньги</td>
          <td>{{ member.Money }} руб.</td>
        </tr>
        <tr>
          <td>Создан</td>
          <td>{{ member.CreatedAt | formatDate }}</td>
        </tr>
        <tr>
          <td>Фото на паспорт</td>
          <td>
            <img
              class="image"
              v-bind:src="baseURL + member.PassportPhoto.Path"
              width="100"
              height="133"
            />
          </td>
        </tr>
        <tr>
          <td>Фото на допуск</td>
          <td>
            <gallery :images="images" :index="index" @close="index = null">
            </gallery>
            <div
              class="image"
              v-for="(image, imageIndex) in images"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{
                backgroundImage: `url( ${image})`,
                width: '100px',
                height: '133px'
              }"
            ></div>
          </td>
        </tr>
        <tr>
          <td>Паспорт</td>
          <td>
            <div
              v-if="
                member.PassportImages.FrontImg.length > 0 &&
                  member.PassportImages.BackImg.length > 0 &&
                  member.PassportImages.QRCodeImg.length > 0
              "
            >
              <p>
                <a
                  :href="baseURL + member.PassportImages.FrontImg"
                  target="_blank"
                  >лицевая сторона</a
                >
              </p>
              <p>
                <a
                  :href="baseURL + member.PassportImages.BackImg"
                  target="_blank"
                  >тыльная сторона</a
                >
              </p>
              <p>
                <a
                  :href="baseURL + member.PassportImages.QRCodeImg"
                  target="_blank"
                  >QR-код</a
                >
              </p>
            </div>
            <div>
              <b-button
                v-if="member.PassportImages.FrontImg.length === 0"
                size="sm"
                variant="success"
                @click="passportGenerate"
              >
                Сгенерировать паспорт
              </b-button>
              <b-button
                v-if="member.PassportImages.FrontImg.length > 0"
                size="sm"
                @click="passportGenerate"
              >
                Перегенерировать паспорт
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <b-modal
      ref="legendModal"
      class="modal-info"
      size="md"
      scrollable
      ok-only
      :title="'Легенда сталкера ' + member.Callsign"
    >
      <div class="d-block text-center">
        <p>{{ member.Legend }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueGallery from "vue-gallery";
import API from "../api/stalk.net";

export default {
  name: "MemberData",
  props: {
    baseURL: String,
    member: Object,
    images: Array,
    getRank: Function
  },
  data() {
    return {
      isPageLoading: false,
      index: null
    };
  },
  components: {
    gallery: VueGallery
  },
  computed: {},
  filters: {},
  methods: {
    showLegend() {
      this.$refs.legendModal.show();
    },
    passportGenerate() {
      let self = this;

      self.isPageLoading = true;
      API.private
        .passportGenerate(self.member.Id)
        .then(resp => {
          self.member.PassportImages = resp.data;

          self.isPageLoading = false;
        })
        .catch(self.catchError);
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
      self.isPageLoading = false;
    }
  }
};
</script>

<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  -moz-opacity: 0.5;
  filter: alpha(opacity=50);
  opacity: 0.5;
  z-index: 1001;
}
</style>
